import { createClientMessage } from 'react-chatbot-kit';
import React, { useRef,useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as bootstrap from 'bootstrap';
import { BiSolidPurchaseTag } from "react-icons/bi";
import { auth, db } from '../../firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import './chatbot.css'
import PreBuilt from '../Components/PreBuilt';

const ActionProvider = ({ createChatBotMessage, setState, children}) => {

  const [fetchedData, setFetchedData] = useState([]);


  const [companyList, setCompanyList] = useState([]);
  const [selectedcompany, setSelectedcompany] = useState("");
  const [selectedyear, setSelectedyear] = useState(null);
  const [selectedquarter, setSelectedquarter] = useState("")
  const [selectedquery, setSelectedquery] = useState("")
  const [availableYears, setAvailableYears] = useState([]);
  const [availableQuarters, setAvailableQuarters] = useState([]);
  const [context, setContext] = useState('test')
  const [user, setUser] = useState(null);
  const [uid, setUid] = useState("");
  const [usermail, setUsermail] = useState("");
  const [userCredits, setUserCredits] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [accordionData, setAccordionData] = useState([]);
  const [actualprompt, setActualprompt] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [data, setData] = useState([
    {company: "", quarter: "", query: "", year: ""}
  ]
  );




  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setUsermail(currentUser.email);
        setUid(currentUser.uid);
  
        const querySnapshotNew = await getDocs(
          query(
            collection(db, "userdata"),
            where("email", "==", currentUser.email)
          )
        );
  
        if (!querySnapshotNew.empty) {
          const userData = querySnapshotNew.docs[0].data();
          setUserCredits(userData.credits);
        }
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  const fetchData = async () => {
    const companyDetails = selectedcompany + "_" + selectedyear + "_" + selectedquarter;
    try {
        const response = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/question-answer/${companyDetails}`);
        if (response.status === 200) {
            setAccordionData(response.data);
        } else {
            console.error('Failed to fetch data');
            setAccordionData([])
        }
    } catch (error) {
        console.error('Error:', error);
        setAccordionData([])
    }

    try {
      // Fetch the prompt data
      const promptResponse = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/stocksPrompt/defaultPrompt`);
      if (promptResponse.status === 200) {
          // Update the prompt state with the fetched prompt data
          setPrompt(promptResponse.data.prompt.finalPrompt)
      } else {
          console.error('Failed to fetch prompt data');
          setPrompt(null);
      }
  } catch (error) {
      console.error('Error fetching prompt:', error);
      setPrompt(null);
  }

  
};


  useEffect(() => {
    fetchData();
}, [selectedquarter]);

const generateActualPrompt = () => {
  // Replace placeholders in the prompt string with actual selected values
  const replacedPrompt = prompt
    .replace(/{company}/g, selectedcompany)
    .replace(/{year}/g, selectedyear)
    .replace(/{quarter}/g, selectedquarter);

  // Set the updated prompt to actualprompt state
  setActualprompt(replacedPrompt);
};

useEffect(() => {
  console.log(actualprompt);
}, [actualprompt]);




  useEffect(() => {
    if(prompt){
       generateActualPrompt();
      }
    
}, [prompt]);

useEffect(() => {
  fetchData();
  
}, [selectedquarter]);




  const changeCredits = async () => {
    const querySnapshotNew = await getDocs(
      query(collection(db, "userdata"), where("email", "==", user.email))
    );
  const documentRef = doc(db, "userdata", querySnapshotNew.docs[0].id);
  updateDoc(documentRef, {
  credits: userCredits - 1,
  });
  
  setUserCredits((prev) => prev - 1);
  }



  // useEffect(() => {
  //   const fetchCompanies = async () => {
  //     try {
  //       const response = await axios.get('https://pinecone-api-umfjwnddla-em.a.run.app/api/companies');
  //       if (response.status === 200) {
  //         setCompanyList(response.data.companies);
  //       } else {
  //         console.error('Failed to fetch companies');
  //       }
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   };

  //   fetchCompanies();
  // }, [selectedcompany]);

useEffect(() => {
  const fetchCompanies = async () => {
    try {
      const response = await axios.get('https://pinecone-api-umfjwnddla-em.a.run.app/api/companies');
      if (response.status === 200) {
        setCompanyList(response.data.companies);
      } else {
        console.error('Failed to fetch companies');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  fetchCompanies();
}, []);

// useEffect(() => {
//   // Fetch companies and filter out duplicates
//   const fetchCompanies = async () => {
//     try {
//       const response = await axios.get('https://pinecone-api-umfjwnddla-em.a.run.app/api/companies');
//       if (response.status === 200) {
//         const compu =  ;

//         // Extract unique company names
//         const uniqueCompanyNames = Array.from(new Set(compu.map(company => company.name)));
//         setCompanyList(uniqueCompanyNames.map(name => ({ name })));
//       } else {
//         console.error('Failed to fetch companies');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   fetchCompanies();
// }, []);

  
  

  // const updateselect = async (data, check) => {
  //   if (check === "company") {
  //     setSelectedcompany(data);
  //     // Find the selected company in the companyList
  //     const companyData = companyList.find((company) => company.name === data);
  
  //     console.log("Company Data:", companyData);
  
  //     if (companyData) {
  //       // No need to check for quarters, directly access year
  //       const uniqueYears = companyData.year
  //         ? [companyData.year]
  //         : [];
  //       setAvailableYears(uniqueYears);
  //       console.log(uniqueYears + " hhh")
  //       // No need for separate quarters array
  //       const uniqueQuarters = companyData.quarter
  //       ? [companyData.quarter]
  //       : [];
  //     setAvailableQuarters(uniqueQuarters);
  //     console.log(uniqueYears  + " " + uniqueQuarters)
  //     }
  //   } else if (check === "year") {
  //     setSelectedyear(data);
  //   } else if (check === "quarter") {
  //     setSelectedquarter(data);


  //     console.log(selectedcompany + " " + selectedyear + " " + data + " test")
  //   }
  // };

  // const updateselect = async (data, check) => {
  //   if (check === "company") {
  //     setSelectedcompany(data);
  //     // Filter the companyList to get entries for the selected company
  //     const selectedCompanyData = companyList.filter(company => company.name === data);
  
  //     if (selectedCompanyData.length > 0) {
  //       // Get all years and quarters associated with the selected company
  //       const allYears = selectedCompanyData.flatMap(company => company.year);
  //       const allQuarters = selectedCompanyData.flatMap(company => company.quarter);
  
  //       // Filter out unique years and quarters
  //       const uniqueYears = Array.from(new Set(allYears));
  //       const uniqueQuarters = Array.from(new Set(allQuarters));
  
  //       setAvailableYears(uniqueYears);
  //       setAvailableQuarters(uniqueQuarters);
  //     }
  //   } else if (check === "year") {
  //     setSelectedyear(data);
  //   } else if (check === "quarter") {
  //     setSelectedquarter(data);
  //   }
  // };
  const updateselect = async (data, check) => {
    if (check === "company") {
      setSelectedcompany(data);
      // Filter the companyList to get entries for the selected company
      const selectedCompanyData = companyList.filter(company => company.name === data);
  
      if (selectedCompanyData.length > 0) {
        // Get all years associated with the selected company
        const allYears = selectedCompanyData.flatMap(company => company.year);
        const uniqueYears = Array.from(new Set(allYears));
        setAvailableYears(uniqueYears);
  
        // Clear selected year and quarter when changing the company
        setSelectedyear("");
        setSelectedquarter("");
      }
    } else if (check === "year") {
    
  
      // Filter the companyList to get entries for the selected company
      const selectedCompanyData = companyList.filter(company => company.name === selectedcompany);
  
      if (selectedCompanyData.length > 0) {
        // Get all quarters associated with the selected company and year
        const selectedYearData = selectedCompanyData.filter(company => company.year === data);
        const allQuarters = selectedYearData.flatMap(company => company.quarter);
        const uniqueQuarters = Array.from(new Set(allQuarters));
        setAvailableQuarters(uniqueQuarters);
  
        // Clear selected quarter when changing the year
        setSelectedquarter("");
        setSelectedyear(data);
      }
    } else if (check === "quarter") {
      setSelectedquarter(data);
      console.log(selectedcompany + " " + selectedyear + " " + data)
    }
  };
  
  
  
  
  // useEffect(() => {
  //   // Additional actions can be performed here
  //   // This block runs after the state updates are complete
  //   // For example, resetting selected year and quarter
  //   setSelectedyear("");
  //   setSelectedquarter("");
  //   console.log("ok " +selectedcompany + " " + selectedyear + " " + selectedquarter + " test" )
  // }, [availableYears, availableQuarters]);
  
  const apiUrl = 'https://pinecone-api-umfjwnddla-em.a.run.app/api/retriever';

  const initialAction = () => {
    const cmessage = createClientMessage("Let's get started");
    updateState(cmessage)
    if(user){
    const message = createChatBotMessage("Sure, Let's move ahead. \n Enter a company");
    updateState(message, "quarter")
    }else{
      const message = createChatBotMessage("Please sign in");
      updateState(message, "null")
  }
  };

  const comp = (compName) => {
    const newData = [...data];
    newData[0].company = compName;
    setData(newData);
    console.log(data);
    const message = createChatBotMessage("Enter year")
    updateState(message, "year")
  }


  const yearval = (year) => {
    const newData = [...data];
    newData[0].year = year;
    setData(newData);
    console.log(data); // Check if the data is updated correctly
    setSelectedyear(year); // Update the selected year state
    const message = createChatBotMessage("Cool! Now enter the quarter. Your Quarter should be one of these - Q1/Q2/Q3/Q4")
    updateState(message, "query");
}




  const quart = (quarter) => {
   
    const newData = [...data];
    newData[0].quarter = quarter;
    setData(newData);
    console.log(data);
    const message = createChatBotMessage("Enter your query", {
      widget:'PreBuilt',
    })
    updateState(message, "final");

  }
  
  const querypost = async (query) => {


    if(user){


      if(userCredits !== null && userCredits !== 0){


      setSelectedquery(query)

      if(usermail=="prashantk@dataknobs.com" || usermail=="vishnu.dn@dataknobs.com"){
      const message = createChatBotMessage("Choose an option", {
        widget:'Get',
      })
      updateState(message,"final")
    }else{
      getAnswer();
    }



    }
      else{
      
        const message = createChatBotMessage("Oops! You are out of credits", {
          widget:'null',
        });
        updateState(message);
        setIsLoading(false);
      }
  


      }else{
        const message = createChatBotMessage("Please sign in");
        updateState(message, "null")
    }


    // console.log(data)
  }

  // const finish = () => {
  //   const message = createChatBotMessage('Oops! you have run out of query limit :( ');
  //   updateState(message);
  // }


const getAnswer = async () => {

const updatedPrompt = actualprompt + ". " + selectedquery;
  
  const params = {
    get: 'answer',
    company: selectedcompany,
    year: selectedyear,
    quarter: selectedquarter,
    query: updatedPrompt,
    model: 'gemini',
  };

  setIsLoading(true);

  try {
    const response = await axios.get(apiUrl, { params });

    setIsLoading(false);

    if (response.status === 200) {
      console.log(response.data)
      const message = createChatBotMessage(response.data.llmResponse, {
        widget: 'review',
      });
      updateState(message, 'final');
      changeCredits();
    } else {
      console.log('error');
    }
  } catch (error) {
    console.error('Error:', error);
    setIsLoading(false);
  }
};

  const getAnswr = async () => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-functions-key': 'tM6Yf4uzqbE2PolTAuOoW4OoUzIUNI9jExf4j2UVPZ0bAzFueZslxw==',
    };
    setIsLoading(true);

    const response = await axios.post('https://earning-call-index.azurewebsites.net/get_answer', data[0], { headers });

    setIsLoading(false);

    if (response.status === 200) {
      const message = createChatBotMessage(response.data, {
        widget:'review',
      });
      updateState(message, "final") ;
    } else {
      console.log("error");
    }

  };

  const getContext = () => {
  
    const params = {
      get: 'context',
      company: selectedcompany,
      year: selectedyear,
      quarter: selectedquarter,
      query: selectedquery,
      model: 'gemini',
    };
  
    setIsLoading(true);
  
    axios.get(apiUrl, { params })
      .then((response) => {
        setIsLoading(false);
  
        if (response.status === 200) {
          setContext(response.data.context)
          const message = createChatBotMessage(response.data.context, {
            widget: 'review',
          });
          updateState(message, 'final');
          changeCredits();
        } else {
          console.log('error');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  };
  
  

    // const headers = {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   'x-functions-key': 'tM6Yf4uzqbE2PolTAuOoW4OoUzIUNI9jExf4j2UVPZ0bAzFueZslxw==',
    // };
    // setIsLoading(true);

    // const response = await axios.post('https://earning-call-index.azurewebsites.net/get_context', data[0], { headers });

    // setIsLoading(false);

    // if (response.status === 200) {
    //   setContext(response.data)
    //   const message = createChatBotMessage(response.data, {
    //     widget:'review',
    //   });
    //   updateState(message, "final") ;
    // } else {
    //   console.log("error");
    // }


   const updateState = (message, checker) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      checker,
    })) 
  };

  const [isChildDropdownOpen, setIsChildDropdownOpen] = useState(false);
  const parentDropdownRef = useRef(null);

  const toggleChildDropdown = () => {
    setIsChildDropdownOpen(!isChildDropdownOpen);
  };




  return (
    <div>
      <PreBuilt  accordionData={accordionData}   />
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            initialAction,
            comp,
            yearval,
            quart,
            querypost,
            getAnswer,
            getContext,

            context,
          },


        }
        
        
        
        );
      })}

{isLoading && (
  <div class="shapes"></div>
)}

<div className='range' style={{position:'fixed'}}>
<div style={{marginTop:"10px"}}>
    <span>(     Available Credits:{" "}
                {userCredits ? userCredits : 0}{" "} )</span> <span className='credit'>&nbsp;<Link to="/creditsPricing" style={{color:"white"}}><BiSolidPurchaseTag style={{color:"white"}}/>Buy More</Link></span>
  
  </div>
  <br/>
  {/* <div class="dropdown-center">
          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {selectedcompany ? selectedcompany : "Select Company"}
          </button>
          <ul class="dropdown-menu" style={{ maxHeight: '20vh', overflowY: 'auto' }}>
            {companyList.map((company) => (
              <li key={company.name}>
                <button class="dropdown-item" onClick={() => updateselect(company.name, "company")}>
                  {company.name}
                </button>
              </li>
            ))}
          </ul>
        </div> */}
        <div class="dropdown-center">
  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{backgroundColor:"#e3ebf7", color:"#285192"}}>
    {selectedcompany ? selectedcompany : "Select Company"}
  </button>
  <ul class="dropdown-menu" style={{ maxHeight: '20vh', overflowY: 'auto' }}>
    {[...new Set(companyList.map(company => company.name))].map((companyName) => (
      <li key={companyName}>
        <button class="dropdown-item" onClick={() => updateselect(companyName, "company")}>
          {companyName}
        </button>
      </li>
    ))}
  </ul>
</div>

<br/>



<div class="dropdown-center">
<button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{backgroundColor:"#e3ebf7", color:"#285192"}}>
    {selectedyear ? selectedyear : "Select Year"}
</button>
<ul class="dropdown-menu" >
    {availableYears.map((year) => (
        <li key={year}>
            <button class="dropdown-item" onClick={() => updateselect(year, "year")} >
                {year}
            </button>
        </li>
    ))}
</ul>

</div>

<br/>

<div class="dropdown-center">
  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{backgroundColor:"#e3ebf7", color:"#285192"}}>
    {selectedquarter ? selectedquarter : "Select Quarter"}
  </button>
  <ul class="dropdown-menu">
    {availableQuarters.map((quarter) => (
      <li key={quarter}>
        <button class="dropdown-item" onClick={() => updateselect(quarter, "quarter")}>
          {quarter}
        </button>
      </li>
    ))}
  </ul>
</div>



  </div>
    </div>
  );
};

export default ActionProvider;

