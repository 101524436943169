import { useState, useEffect } from "react";
import axios from "axios";

const PreBuilt = ({ accordionData, props }) => {
  const [expandedItems, setExpandedItems] = useState([]);

  const toggleAccordion = (index) => {
    const currentIndex = expandedItems.indexOf(index);
    const newExpandedItems = [...expandedItems];
    if (currentIndex === -1) {
        newExpandedItems.push(index); // Expand if not already expanded
    } else {
        newExpandedItems.splice(currentIndex, 1); // Collapse if already expanded
    }
    setExpandedItems(newExpandedItems);
  };

  return ( 
    <>
   
    </>
  );
};
 
export default PreBuilt;
