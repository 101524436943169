//earnings-call
import './App.css';
import Main from './Main';

function App() {

  return (
    <div className='App'>

      <Main/>

    </div>
  );
}

export default App;