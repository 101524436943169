import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { MdOutlineFlag, MdOutlineEdit } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import './Components.css'
import { useState, useEffect } from "react";
import {auth} from '../../firebase'

const Review = (props) => {

  const [usermail, setUsermail] = useState("");

  const handleSaveClick = () => {
    const editedContent = document.querySelector('.modal-body').textContent;
    console.log(editedContent);
  };
  

  const edit = () => {
    props.actions.editContext()
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUsermail(currentUser.email)
      }
    });




    return () => unsubscribe();
  }, []);


    return ( 
        <div>
        <div>
          <div className="r-icon">
            <div className="oicon">
              <FiThumbsUp className="f-icon" />
              <div className="hoverfIcon"><p>Like the response :)</p></div>
            </div>
            <div className="oicon">
              <FiThumbsDown className="s-icon" />
              <div className="hoversIcon"><p>Dislike the response :(</p></div>
            </div>
            <div className="oicon">
              <MdOutlineFlag  className="t-icon"/>
              <div className="hovertIcon"><p>Report</p></div>
            </div>
            <div className="oicon">
            {dropdownOpen && (
          <div className="dropdown">
           
              <div className="doptions">Unnecessary info</div>
              <div className="doptions">Incomplete answer</div>
              <div className="doptions">Use formal language</div>
          
           
          </div>
        )}
              <BsThreeDotsVertical  className="t-icon"  onClick={handleDropdownClick}/>
              <div className="hovertIcon"><p>More</p></div>
              
            </div>

            {(usermail=='vishnu.dn@dataknobs.com' || usermail=='prashantk@dataknobs.com') && (            <div className="oicon">
              <MdOutlineEdit className="s-icon" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"/>
              <div className="hoversIcon"><p>Edit Context</p></div>
            </div>)}

          </div>

        
         
        </div>



        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Edit Context</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" contentEditable="true">
        {props.actions.context}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" onClick={handleSaveClick}>Save</button>
      </div>
    </div>
  </div>
</div>

        
      </div>
     );
}
 
export default Review;