import React, { useEffect, useState } from "react";
import { auth } from "../../firebase.js";
import axios from "axios";

const MessageParser = ({ children, actions }) => {
  const { checker } = children.props.state;
  const user = auth.currentUser;
  const [sessionID, setSessionID] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);

  const createSession = async () => {
    if (user) {
      const userEmail = user.email;
      try {
        const response = await axios.post(
          "https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/createSession",
          {
            userEmail,
          }
        );
        setSessionID(response.data.sessionID);
      } catch (error) {
        console.error("Error creating session:", error);
      }
    }
  };

  const saveChatHistory = async (message) => {
    if (user && sessionID) {
      try {
        await axios.post(
          "https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/saveSession",
          {
            userEmail: user.email,
            sessionID,
            message,
          }
        );
      } catch (error) {
        console.error("Error saving chat history:", error);
      }
    }
  };

  const parse = async (message) => {

    // if (checker === "year") {
    //   const capitalizedMessage =
    //     message[0].toUpperCase() +
    //     message.substring(1, message.length).toLowerCase();
    //   actions.yearval(capitalizedMessage);
    //   // children.props.state.dataa.ccName = capitalizedMessage;
    // }


    // if (checker === "quarter") {
    //   const capitalizedMessage =
    //     message[0].toUpperCase() +
    //     message.substring(1, message.length).toLowerCase();
    //   actions.comp(capitalizedMessage);
    //   children.props.state.dataa.ccName = capitalizedMessage;
    // }


    // if (checker === "query") {
    //   const capitalizedMessage = message.toUpperCase() ;
    //   actions.quart(capitalizedMessage);
    //   children.props.state.dataa.ccQuarter = capitalizedMessage;
    //   // if (capitalizedMessage === "Q1") {
    //   //   actions.quart("Previous Quarter");
    //   // }
    //   // if (capitalizedMessage === "Q2") {
    //   //   actions.quart("Current Quarter");
    //   // }
    // }
    // if (checker === "final") {
    //   actions.querypost(message);
    // }

    actions.querypost(message);

    setChatHistory((prevHistory) => [...prevHistory, message]);
    saveChatHistory(message);
  };

  useEffect(() => {
    // Start a new session when the component mounts
    createSession();
  }, [user]);

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;