import React from 'react'

export default function Amazon(props) {

    const comp = (compName) => {
        props.actions.comp(compName);
    }
   
    return (
        <div>
            <button className='company1' onClick={() => comp('Amazon')}>Amazon</button>
            <button className='company' onClick={() => comp('Alphabet')}>Alphabet</button>
            <button className='company' onClick={() => comp('Meta')}>Meta</button>
            <button className='company' onClick={() => comp('Tesla')}>Tesla</button>
        </div >
    )
}
