import React from 'react'
import './knobs.css';

const Knobs = () => {
  return (
    <div className='MainDiv'>
        <div className="knobsDiv">
            <div className='sliderDiv'>
                <div className='slider' style={{width:"5rem"}}>
                    <label htmlFor="" >Input</label>
                </div>
                <div className='slider'>
                    <input type="range" className='range-slider' id='range-slider1' min={10} max={100} defaultValue={50} step={10} />
                </div>
                <div className='slider'>
                    <input type="range" className='range-slider' id='range-slider2' min={10} max={100} defaultValue={50} step={10} />
                </div>
                <div className='slider'>
                    <input type="range" className='range-slider'  id='range-slider3' min={10} max={100} defaultValue={50} step={10} />
                </div>
            </div>
            

            <div className='sliderDiv'>
                <div className='slider' style={{width:"5rem"}}>
                    <label htmlFor="">Output</label>
                </div>
                <div className='slider'>
                    <input type="range" className='range-slider'  id='range-slider4' min={10} max={100} defaultValue={50} step={10} />
                </div>
                <div className='slider'>
                    <input type="range" className='range-slider'  id='range-slider5' min={10} max={100} defaultValue={50} step={10} />
                </div>
                <div className='slider'>
                    <input type="range" className='range-slider'  id='range-slider6' min={10} max={100} defaultValue={50} step={10} />
                </div>
            </div>

            {/* <div>
                <div className='slider'>
                    <input type="range" className='range-slider' min={10} max={100} defaultValue={50} step={10} />
                </div>
                <div className='slider'>
                    <input type="range" className='range-slider' min={10} max={100} defaultValue={50} step={10} />
                </div>
            </div> */}

        </div>

        <div className="selectBox">
            <div className="checkbox1">
                <input type="checkbox" id='' />
                <label htmlFor="" style={{margin:"0px 5px"}}>PII Validation</label>
            </div>
           
            <div className="checkbox2">
                <input type="checkbox" id=''/>
                <label htmlFor="" style={{margin:"0px 5px"}}>Sensitive Info Validation</label>
            </div>
            
        </div>
        
    </div>
    
  )
}

export default Knobs
