import axios from "axios";
// import * as dotenv from 'dotenv'
// dotenv.config()

export const GetToken= async ()=>{

    const formData = new FormData();
    formData.append('username','gopal');
    formData.append('password','1234');
    // http://52.90.177.197/docs    http://127.0.0.1:8000  http://52.90.177.197/token
   const data = await axios.post(`${process.env.REACT_APP_endpoint}/token`,formData)
   .catch(err=>console.log(err));
   console.log("Got token");
//    console.log(data);
   return await data.data;
}

export const GetFromChagpt = async (token,quesition)=>{
    // const quesition = "write code for knapsack problem";
    const data = await axios.get(`${process.env.REACT_APP_endpoint}/askfromgpt?quesiton=${quesition}&index=test`,
    {
        headers:{
            "Authorization":`Bearer ${token}`
          }
    }

    )
    .catch(err=>{
        console.log("err in calling api");
        console.log(err);
    });

    // console.log(data);

    return  data.data;
}

export const askQuestion = async(token,quesiton,topK,threshold)=>{

    const data = await axios.get(`${process.env.REACT_APP_endpoint}/askqustion?quesiton=${quesiton}&index=test&topk=${topK}&threshold=${threshold}`, {
        headers:{
            "Authorization":`Bearer ${token}`
          }
    })
    .catch(err=>{
        console.log("error in askquestion api");
        console.log(err);
    })

    // console.log(data);
    return data.data;

}