
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const PreBuilt = () => {
    const [accordionData, setAccordionData] = useState([]);
    const [expandedItem, setExpandedItem] = useState(null);


    const [companyList, setCompanyList] = useState([]);
    const [selectedcompany, setSelectedcompany] = useState(null);
    const [selectedyear, setSelectedyear] = useState(null);
    const [selectedquarter, setSelectedquarter] = useState("")
    const [availableYears, setAvailableYears] = useState([]);
    const [availableQuarters, setAvailableQuarters] = useState([]);
    const [accordionData1, setAccordionData1] = useState([]);
    const [expandedItems1, setExpandedItems1] = useState([]);
    const [faq, setFaq] = useState(false);

    const toggleAccordion1 = (index) => {
      const currentIndex = expandedItems1.indexOf(index);
      const newExpandedItems = [...expandedItems1];
      if (currentIndex === -1) {
          newExpandedItems.push(index); // Expand if not already expanded
      } else {
          newExpandedItems.splice(currentIndex, 1); // Collapse if already expanded
      }
      setExpandedItems1(newExpandedItems);
  };


  
    useEffect(() => {
        const fetchCompanies = async () => {
          try {
            const response = await axios.get('https://pinecone-api-umfjwnddla-em.a.run.app/api/companies');
            if (response.status === 200) {
              setCompanyList(response.data.companies);
              console.log(response.data.companies)
            } else {
              console.error('Failed to fetch companies');
            }
          } catch (error) {
            console.error('Error:', error);
          }
        };
      
        fetchCompanies();
      }, []);





      
      const fetchData = async () => {
        const companyDetails = selectedcompany + "_" + selectedyear + "_" + selectedquarter;
        try {
            const response = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/question-answer/${companyDetails}`);
            if (response.status === 200) {
                setAccordionData1(response.data);
            } else {
                console.error('Failed to fetch data');
                setAccordionData1([]);
            }
        } catch (error) {
            console.error('Error:', error);
            setAccordionData1([]);
        }

        
    };



    useEffect(() => {
      fetchData();
      
  }, [selectedquarter]);

      const updateselect = async (data, check) => {
        if (check === "company") {
          setSelectedcompany(data);
          // Filter the companyList to get entries for the selected company
          const selectedCompanyData = companyList.filter(company => company.name === data);
      
          if (selectedCompanyData.length > 0) {
            // Get all years associated with the selected company
            const allYears = selectedCompanyData.flatMap(company => company.year);
            const uniqueYears = Array.from(new Set(allYears));
            setAvailableYears(uniqueYears);
      
            // Clear selected year and quarter when changing the company
            setSelectedyear("");
            setSelectedquarter("");
          }
        } else if (check === "year") {
        
      
          // Filter the companyList to get entries for the selected company
          const selectedCompanyData = companyList.filter(company => company.name === selectedcompany);
      
          if (selectedCompanyData.length > 0) {
            // Get all quarters associated with the selected company and year
            const selectedYearData = selectedCompanyData.filter(company => company.year === data);
            const allQuarters = selectedYearData.flatMap(company => company.quarter);
            const uniqueQuarters = Array.from(new Set(allQuarters));
            setAvailableQuarters(uniqueQuarters);
      
            // Clear selected quarter when changing the year
            setSelectedquarter("");
            setSelectedyear(data);
          }
        } else if (check === "quarter") {
          setSelectedquarter(data);
          console.log(selectedcompany + " " + selectedyear + " " + data)
          setFaq(true)
        }
      };
      

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/all-question-answer');
                if (response.status === 200) {
                    setAccordionData(response.data);
                } else {
                    console.error('Failed to fetch data');
                    setAccordionData([]);
                }
            } catch (error) {
                console.error('Error:', error);
                setAccordionData([]);
            }
        };

        fetchData();
    }, []);

    const toggleAccordion = (index) => {
        if (expandedItem === index) {
            setExpandedItem(null); // Collapse if already expanded
        } else {
            setExpandedItem(index); // Expand if not already expanded
        }
    };

    return (
        <div style={{ paddingTop: '5%', paddingLeft: '5%' }} className="prebuilt">

            <h3 style={{ marginTop: '5%' }}>Pre-built questions</h3>

        <div style={{display:"flex", width:"70vw", marginTop:"5%"}}>



 <div class="dropdown-center" >
 <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
 {selectedcompany ? selectedcompany : "Select Company"}
 </button>
 <ul class="dropdown-menu" style={{ maxHeight: '20vh', overflowY: 'auto' }}>
 {[...new Set(companyList.map(company => company.name))].map((companyName) => (
    <li key={companyName}>
      <button class="dropdown-item" onClick={() => updateselect(companyName, "company")}>
        {companyName}
      </button>
    </li>
  ))}
</ul>
</div>




<div class="dropdown-center" style={{marginLeft:"5%"}}>
<button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" color='black'>
  {selectedyear ? selectedyear : "Select Year"}
</button>
<ul class="dropdown-menu" >
  {availableYears.map((year) => (
      <li key={year}>
          <button class="dropdown-item" onClick={() => updateselect(year, "year")} >
              {year}
          </button>
      </li>
  ))}
</ul>

</div>



<div class="dropdown-center" style={{marginLeft:"5%"}}>
<button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  {selectedquarter ? selectedquarter : "Select Quarter"}
</button>
<ul class="dropdown-menu">
  {availableQuarters.map((quarter) => (
    <li key={quarter}>
      <button class="dropdown-item" onClick={() => updateselect(quarter, "quarter")}>
        {quarter}
      </button>
    </li>
  ))}
</ul>
</div>


</div>






{faq ? ( <div className="accordion" id="accordionExample" style={{marginTop:"5%"}}>
              {accordionData1.map((item, index) => (
                  <div className="accordion-item " key={index}>
                      <h2 className="accordion-header " id={`heading${index}`}>
                          <button
                              className={`accordion-button shadow border border-success border-opacity-25 ${expandedItems1.includes(index) ? '' : 'collapsed'}`}
                              type="button"
                              onClick={() => toggleAccordion1(index)} // Toggle on click
                          >
                              {item.question ? item.question : 'Question'}
                          </button>
                      </h2>
                      <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${expandedItems1.includes(index) ? 'show' : ''}`}
                          aria-labelledby={`heading${index}`}
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body" style={{ paddingLeft: "2%" }}>
                              {item.answer ? item.answer : 'Answer'}
                          </div>
                      </div>
                  </div>
              ))}
          </div>
) : (          <div className="accordion" id="accordionExample" style={{ marginTop: '5%' }}>
{accordionData.map((item, index) => (
    <div className="accordion-item" key={index}>
        <h2 className="accordion-header" id={`heading${index}`}>
            <button
                className={`accordion-button shadow border border-success border-opacity-25 ${expandedItem === index ? '' : 'collapsed'}`}
                type="button"
                onClick={() => toggleAccordion(index)}
            >
                {item.companyDetails ? item.companyDetails : 'Company Details'}
            </button>
        </h2>
        <div
            id={`collapse${index}`}
            className={`accordion-collapse collapse ${expandedItem === index ? 'show' : ''}`}
            aria-labelledby={`heading${index}`}
            data-bs-parent="#accordionExample"
        >
            <div className="accordion-body" style={{ paddingLeft: '2%' }}>
                <ul>
                    {item.questions.map((question, idx) => (
                        <li key={idx}>
                            <h6> {question.question}</h6>
                             {question.answer} <br/><br/>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </div>
))}
</div>)
}



















            <br/>
  
        </div>
    );
};

export default PreBuilt;


