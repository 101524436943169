import React, { useEffect, useState } from 'react'
import './home.css'
import { GetFromChagpt, GetToken, askQuestion } from '../api-helper/api-helper';
const Home = () => {
   const [threshold,setThreshold]=useState(50);
   const [topK,setTopK]=useState(5);

   const [loadresult,setLoadresult]=useState(false);
   const [loadresultset,setLoadresultset]=useState(false);
   const [result,setResult]=useState("");
   const [resultset,SetResultset]=useState([]);

   const [que,setQue]=useState("");

//    const handleQuestionsClick = ()=>{
//         console.log(threshold);
//         console.log(topK);
//    }

    const handleQuestionsClick = async ()=>{
        console.log("ask_que");
      
        if(!que || que.trim()===""){
            return alert("Please Enter Valid Quetion");
        }
        setLoadresultset(true);
        await GetToken().then(data=>{
            console.log("success");
            // console.log(data.access_token);
            console.log(data.token_type);

            askQuestion(data.access_token,que,topK,threshold).then(data=>{
                console.log("success");
                // console.log(data);
                SetResultset(data.result);
                setLoadresultset(false);
            })
            .catch(err=>{
                console.log("error in calling askQUE FUCTION");
                console.log(err);
            })

        })
        .catch(err=>{
            console.log("err in calling token func");
            console.log(err);
        })
    }

   const handleChatgptClick = async ()=>{
        console.log("clicked");
       
        if(!que || que.trim()===""){
            return alert("Please Enter Valid Quetion");
        }
        setLoadresult(true);
       await GetToken().then(data=>{
            console.log("success");
            // console.log(data.access_token);
            console.log(data.token_type);

           GetFromChagpt(data.access_token,que).then(data=>{
            console.log("success gpt")
            // console.log(data.result);
            setResult(data.result);
            setLoadresult(false);
           })
           .catch(err=>{
            console.log("error in calling gpt");
            console.log(err);
           })
        })
        .catch(err=>{
            console.log("Error");
            console.log(err);
        })

       
   }

   useEffect(()=>{
        const slidevalue=document.querySelector(".currentvalue");
        const inputrange=document.querySelector(".threshold");

        inputrange.oninput = ()=>{
            let value=inputrange.value;
            slidevalue.textContent=value;
            slidevalue.style.left=(value)+"%";
            setThreshold(value);
        }

        const answerslidevalue=document.querySelector(".currentvalue_answers");
        const answerinputrange=document.querySelector(".answerrange");

        answerinputrange.oninput = ()=>{
            let value=answerinputrange.value;
            answerslidevalue.textContent=value;
            answerslidevalue.style.left=(value*10)+"%";
            setTopK(value);
        }

   })

  return (
    <div className='mainContainer'>
        <div className="innerContainer">
            <div className='heading'>
                {/* <h3>Question Mapping App {process.env.REACT_APP_endpoint}</h3> */}
                <h3>Tool.</h3>
            </div>
            <div className="thresholdSlider">
                <label htmlFor="">set a threshold</label>
                <div className='slidervalue' style={{position:"relative",marginLeft:"8px",width:"98%"}}>
                    <span className='currentvalue' style={{position:"absolute",left:"50%"}}>50</span>
                </div>
                <input className='threshold' type="range" min={0} max={100} step={10} defaultValue={50} />
                <div className='min_max'>
                    <label style={{paddingLeft:"3px"}}>0</label>
                    <label>100</label>
                </div>
            </div>
            <div className="answersSlider">
                <label htmlFor="">set top k answers</label>
                <div className='answerslidervalue' style={{position:"relative",marginLeft:"8px",width:"98%"}}>
                    <span className='currentvalue_answers' style={{position:"absolute",left:"50%"}}>5</span>
                </div>
                <input className='answerrange' type="range" min={0} max={10} step={1} defaultValue={5}/>
                <div className='min_max'>
                    <label style={{paddingLeft:"3px"}}>0</label>
                    <label>10</label>
                </div>
            </div>
            <div className='q_a_btndiv'>
                <button onClick={handleQuestionsClick}>Find questions And Answers</button>
            </div>
            <div className='chatgpt_ans_btndiv'>
                <button onClick={handleChatgptClick}>Get answer from chatgpt and upload it to db</button>
            </div>
        {loadresult &&  <div  style={{color:"red"}}>Loading...</div> }
       { result && <div className="resultContainer">
                <p>
                    {result} 
                </p>
        </div>}

        {loadresultset &&  <div style={{color:"red"}}>Loading...</div> }
        {resultset && 
        <div className="resultContainer" style={{display:"flex",flexDirection:"column"}}>
           {resultset.map((val,ind)=>(
            <p key={ind}>{val}</p>
           ))}
        </div>

        }

        </div>
        
    </div>
  )
}

export default Home
