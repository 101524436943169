import { PaymentElement } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";

export default function CheckoutForm(props) {
  const [user, setUser] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [type, setType] = useState("Default");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });

    if (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      toast.success(`Successfully subscribed to plan ${props.pricingPlan}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      //Allocate a URL to the user
      const domainsCollectionRef = collection(db, "domains");
      const domainsQuery = query(
        domainsCollectionRef,
        where("alloted", "==", false),
        where("type", "==", type),
        limit(1)
      );

      const domainsSnapshot = await getDocs(domainsQuery);
      const allocatedDomainDoc = domainsSnapshot.docs[0];

      if (!domainsSnapshot.empty) {
        await updateDoc(doc(db, "domains", allocatedDomainDoc.id), {
          alloted: true,
        });
      } else {
        toast.error(
          "No available domains to allot. Please mail us and our team will get back to you with the desired domain alloted",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 7000,
          }
        );
        setMessage("No available domains to allot.");
        setIsProcessing(false);
        return;
      }

      const endDate = new Date();
      endDate.setMonth(endDate.getMonth() + 1);

      const subscriptionInfo = {
        start_period: new Date().toISOString(),
        end_period: endDate.toISOString(),
        transaction_id: paymentIntent.id,
        payment_method: paymentIntent.payment_method,
        plan: props.pricingPlan,
        url: allocatedDomainDoc.data().domain,
        github_repository: "",
        gdrive: "",
        subscription: true,
        domain_id: allocatedDomainDoc.id,
        type: type,
        product_type : "websites" // options: websites, datasets, bots
      };

      const membershipInfo = {
        email: user.email,
        subscriptions: [subscriptionInfo],
        prompts: [],
      };

      const membershipsCollectionRef = collection(db, "userdata");
      const existingMembershipQuery = query(
        membershipsCollectionRef,
        where("email", "==", user.email)
      );

      const existingMembershipSnapshot = await getDocs(existingMembershipQuery);

      if (!existingMembershipSnapshot.empty) {
        const existingMembershipDoc = existingMembershipSnapshot.docs[0];
        const existingMembershipData = existingMembershipDoc.data();

        let subscriptions = [];
        if (existingMembershipData.subscriptions) {
          subscriptions = existingMembershipData.subscriptions;
        }
        const updatedSubscriptions = [...subscriptions, subscriptionInfo];

        await updateDoc(existingMembershipDoc.ref, {
          subscriptions: updatedSubscriptions,
        });
      } else {
        await addDoc(membershipsCollectionRef, membershipInfo);
      }

      navigate("/my-account");
    } else {
      toast.error(`An unexpected error occurred.Please try again later`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setMessage("An unexpected error occurred.");
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />

      {/*Input for selecting a site type */}
      <div className="mt-2">
        <label htmlFor="typeSelect" className="mb-1 d-block">
          Site Type
        </label>
        <select
          id="typeSelect"
          className="form-select"
          name="type"
          value={type}
          onChange={(e) => setType(e.target .value)}
          required
          >
          <option value="Default">Default</option>
          <option value=".Net">.Net</option>
          <option value="Node">Node</option>
          <option value="Wordpress">Wordpress</option>
        </select>
      </div>

      <div className="text-center mt-2">
        <button
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          className="btn btn-primary"
        >
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="bg-danger text-white">
          {message}
        </div>
      )}
    </form>
  );
}
