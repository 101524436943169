import React, { useState, useEffect } from "react";
import {
  IoSettingsOutline,
  IoLogInOutline,
  IoLogOutOutline,
} from "react-icons/io5";
import { FaRobot } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import axios from "axios";
import "./Sidebar.css"; // Import your CSS file
import { MdOutlineFilePresent } from "react-icons/md";

const Sidebar = () => {
  const url = process.env.REACT_APP_API_URL;

  const [user, setUser] = useState(null);
  const [botName, setBotName] = useState(null);
  const [botLastName, setBotLastName] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [bgcolor, setBgcolor] = useState(null);
  const navigate = useNavigate();
  const [chatHistory, setChatHistory] = useState([]);
  const fetchChatHistory = async () => {
    if (user) {
      try {
        const userEmail = user.email;
        const response = await axios.get(
          `https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/getChatHistory/${userEmail}`
        );

        if (response.data.success) {
          // Reverse the chat history and limit it to 6 items
          const reversedHistory = response.data.chatHistory[0].questions.reverse().slice(0, 6);
          setChatHistory(reversedHistory);
        } else {
          console.error("Error fetching chat history:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    }
  };

  useEffect(() => {
    fetchChatHistory();
  }, [user]);

  const handleSignOut = (e) => {
    e.preventDefault();

    auth
      .signOut()
      .then(() => {
        window.location.reload();
        navigate("/");
        setUser(null);
      })
      .catch((error) => {
        console.log("Sign out error:", error);
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setBotName(data.bot_first_name);
        setBotLastName(data.bot_last_name);
        setAvatar(data.bot_avatar);
        setBgcolor(data.sidebar_bg_color);
      })
      .catch((error) => {
        console.error("Error fetching bot_name:", error);
      });
  }, []);

  return (
    <div className="ws">
      <div className="top">
        <div className="strip">
          <h1></h1>
          <p className="head">
            Earnings<span className="dot">.</span>Call
          </p>
        </div>
        <div className="options">
          <a href="https://www.kreatebots.com/">
            <p className="icon">
              <AiOutlineHome color="white" />
            </p>
          </a>
          <Link to="/">
            <p className="icon">
              <FaRobot color="white" />
            </p>
          </Link>
          <Link to="/prebuilt">
            <p className="icon" id="setting">
              {" "}
              <MdOutlineFilePresent color="white" className="icons" />
            </p>
          </Link>
          <div className="signin">
            {user ? (
              <div onClick={handleSignOut}>
                <span className="icon">
                  <IoLogOutOutline color="white" />
                </span>
                <span className="signup">&nbsp;Sign out</span>
              </div>
            ) : (
              <Link to="/login">
                <span className="icon">
                  <IoLogInOutline color="white" />
                </span>
                <span className="signup">&nbsp;Sign in</span>
              </Link>
            )}
          </div>
        </div>
      </div>
      {/* <div className="chat-history">
        <h2>Chat History:</h2>
        <ul>
          {chatHistory.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div> */}
      <img
        src="https://storage.googleapis.com/kreatewebsites/sites/site103/img/hero-img.png"
        className="hero"
        alt="Hero"
      />
    </div>
  );
};

export default Sidebar;