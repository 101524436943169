import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import MessageParser from './bot/ChatBot/MessageParser.js';
import ActionProvider from './bot/ChatBot/ActionProvider.js';
import config from './bot/ChatBot/config';
import './App.css'
import {Route,BrowserRouter as Router, Routes} from 'react-router-dom' 
import Sidebar from './sidebar/Sidebar';
import Home from './knob-component/Home';
import Knobs from './knob-component/knobs/Knobs';
import Login from './pages/auth/login'
import ResetPasswordPage from './pages/auth/forgotPassword'
import Signup from './pages/auth/signUp'
import StripePricing from './stripePayment';
import CreditStripePayment from './credits/creditsStripe'
import CreditsPricing from './credits/creditsPricing'
import PreBuilt from './prebuilt/index.js';


const Main = () => {

    return (
         <div className='App'>
          <Router>

          <Sidebar/>
          <Routes>
          <Route exact path='/' element={<Chatbot
            className="cb"
            id='cbt'
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          /> }/>
              <Route path='/Home' element={<Home/>}/>
              <Route path='/knobs' element={<Knobs/>}/>
              <Route path="forgot_password" element={<ResetPasswordPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path='/prebuilt' element={<PreBuilt/>}/>
        <Route path="/stripe-pricing" element={<StripePricing />} />
          <Route
            path="/credit-stripe-pricing"
            element={<CreditStripePayment />}
          />{" "}
          <Route path="/creditsPricing" element={<CreditsPricing />} />
         
   
          </Routes>


          </Router>
    </div>
     );
}
 
export default Main;