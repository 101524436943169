import React from 'react'

export default function Get(props) {

    const getAnswer = () => {
        props.actions.getAnswer();
    }
    const getContext = () => {
        props.actions.getContext();
    }

    return (
        <div>
            <button className='start' onClick={() => getAnswer()}>Get Answer</button>
            <button className='start1' onClick={() => getContext()}>Get Context</button>
        </div >
    )
}